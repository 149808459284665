import * as React from 'react';

export const SecureSkyLogo = () => {
  return (
    <svg 
      width="112px"
      height="23px"
      viewBox="0 0 112 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Logo</title>
      <desc>Created with Sketch.</desc>
      <defs>
          <polygon id="path-1" points="0 0.244864444 22.6239966 0.244864444 22.6239966 22.8688889 0 22.8688889"></polygon>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Menu/X---SecureSky" transform="translate(-20.000000, -24.000000)">
              <g id="Logo" transform="translate(20.000000, 24.000000)">
                  <g id="Group-3" transform="translate(0.000000, 0.034080)">
                      <mask id="mask-2" fill="white">
                          <use xlinkHref="#path-1"></use>
                      </mask>
                      <g id="Clip-2"></g>
                      <path d="M17.3571799,15.6881136 C16.8593632,12.8024502 14.3379288,10.6003436 11.3119843,10.6003436 C8.98465656,10.6003436 7.091001,8.706688 7.091001,6.37908133 C7.091001,4.05175356 8.98465656,2.158098 11.3119843,2.158098 C13.3099443,2.158098 14.9843932,3.55505244 15.4194599,5.42249244 L11.3119843,5.42249244 C10.7837688,5.42249244 10.3551166,5.85086578 10.3551166,6.37908133 C10.3551166,6.90729689 10.7837688,7.33594911 11.3119843,7.33594911 L16.4898354,7.33594911 C18.8171632,7.33594911 20.7105399,9.22932578 20.7105399,11.5569324 C20.7105399,13.5872436 19.2697999,15.2873502 17.3571799,15.6881136 M11.3119843,20.955488 C9.31402433,20.955488 7.63957544,19.5585336 7.20450878,17.6913724 L11.3119843,17.6913724 C11.8401999,17.6913724 12.2685732,17.2627202 12.2685732,16.7345047 C12.2685732,16.2062891 11.8401999,15.7779158 11.3119843,15.7779158 L6.13441211,15.7779158 C3.80708433,15.7779158 1.91342878,13.8842602 1.91342878,11.5569324 C1.91342878,9.52662133 3.35416878,7.82651467 5.26678878,7.42547244 C5.76460544,10.3114147 8.28603989,12.5138002 11.3119843,12.5138002 C13.6393121,12.5138002 15.5329677,14.4074558 15.5329677,16.7345047 C15.5329677,19.0621113 13.6393121,20.955488 11.3119843,20.955488 M17.3817221,5.48691578 C16.9486077,2.52567356 14.3914754,0.244641333 11.3119843,0.244641333 C8.23221433,0.244641333 5.67563989,2.52567356 5.24224656,5.48691578 C2.28100433,5.920588 -2.78888889e-05,8.47744133 -2.78888889e-05,11.5569324 C-2.78888889e-05,14.6367024 2.28100433,17.1932769 5.24224656,17.6266702 C5.67563989,20.5879124 8.23221433,22.8689447 11.3119843,22.8689447 C14.3914754,22.8689447 16.9486077,20.5879124 17.3817221,17.6266702 C20.3432432,17.1932769 22.6239966,14.6367024 22.6239966,11.5569324 C22.6239966,8.47744133 20.3432432,5.920588 17.3817221,5.48691578" id="Fill-1" fill="#279FC7" mask="url(#mask-2)"></path>
                  </g>
                  <path d="M35.6531277,4.65786278 C35.8751232,4.694955 35.9490288,4.78726722 35.9490288,4.95376389 L35.9490288,6.09943944 C35.9490288,6.30247056 35.8564377,6.41374722 35.6715343,6.41374722 L31.4025821,6.41374722 C30.0162254,6.41374722 29.3326688,6.85718056 29.3326688,7.83663833 L29.3326688,8.13226056 C29.3326688,8.90868722 29.5914777,9.37052722 30.5706566,9.77714722 L34.3225488,11.2745017 C36.0597477,11.976465 36.651271,12.9375161 36.651271,14.7302139 L36.651271,15.3033306 C36.651271,18.0754861 34.4703599,18.6667306 31.5687999,18.6667306 C30.1275021,18.6667306 28.6672399,18.5744183 27.0223532,18.334295 C26.8377288,18.2972028 26.7635443,18.2048906 26.7635443,18.0015806 L26.7635443,16.8927183 C26.7635443,16.6894083 26.8558566,16.5970961 27.0594454,16.5970961 L32.2158221,16.5970961 C33.5832143,16.5970961 34.2670499,16.1904761 34.2670499,15.2110183 L34.2670499,14.8967106 C34.2670499,14.0463783 33.9527421,13.5474461 32.8993788,13.1224194 L28.9076421,11.5514383 C27.4102877,10.9970072 26.9484477,9.888145 26.9484477,8.11413278 L26.9484477,7.68882722 C26.9484477,4.91667167 28.9999543,4.343555 31.9386066,4.343555 C33.4724954,4.343555 34.1934232,4.43614611 35.6531277,4.65786278" id="Fill-4" fill="#093D7C"></path>
                  <path d="M40.2673164,12.7159668 L44.4997342,12.7159668 C44.4810487,10.719959 44.0744287,10.2023412 42.4298209,10.2023412 C40.7110287,10.2023412 40.2857231,10.719959 40.2673164,12.7159668 M46.7545509,13.270119 L46.7545509,13.824829 C46.7545509,14.1756712 46.6435531,14.3237612 46.1262142,14.3237612 L40.2673164,14.3237612 C40.3228153,16.2826768 40.7662487,16.726389 42.5592253,16.726389 L45.7195942,16.726389 C45.9042187,16.726389 45.9968098,16.8187012 45.9968098,17.0036046 L45.9968098,18.1492801 C45.9968098,18.352869 45.9042187,18.4267746 45.7380009,18.4635879 C44.7585431,18.6116779 43.6125887,18.6668979 42.4298209,18.6668979 C38.8257398,18.6668979 38.0124998,17.6319412 38.0124998,13.4737079 C38.0124998,9.296789 38.8257398,8.26155344 42.4298209,8.26155344 C45.9042187,8.26155344 46.7174587,9.25969678 46.7545509,13.270119" id="Fill-6" fill="#093D7C"></path>
                  <path d="M55.5122477,8.46508656 C55.6968721,8.50217878 55.7894632,8.594491 55.7894632,8.79752211 L55.7894632,9.92506989 C55.7894632,10.1096943 55.6968721,10.2022854 55.493841,10.2022854 L52.5920021,10.2022854 C50.614401,10.2022854 50.355871,10.8493077 50.355871,13.4552454 C50.355871,16.0609043 50.614401,16.7263332 52.5920021,16.7263332 L55.493841,16.7263332 C55.6968721,16.7263332 55.7894632,16.8186454 55.7894632,17.0035488 L55.7894632,18.1308177 C55.7894632,18.3344066 55.6968721,18.4267188 55.5122477,18.463811 C54.532511,18.6300288 53.3313366,18.6668421 52.5920021,18.6668421 C48.8403888,18.6668421 48.1010543,17.6318854 48.1010543,13.4552454 C48.1010543,9.29673322 48.8403888,8.26177656 52.5920021,8.26177656 C53.3313366,8.26177656 54.532511,8.29858989 55.5122477,8.46508656" id="Fill-8" fill="#093D7C"></path>
                  <path d="M65.7136128,8.35422822 C65.8982372,8.35422822 65.9908283,8.44654044 65.9908283,8.63144378 L65.9908283,18.0570516 C65.9908283,18.2971749 65.8982372,18.3158604 65.7136128,18.3529527 C64.3459417,18.6301682 62.701055,18.6669816 61.5921928,18.6669816 C58.173015,18.6669816 57.2119639,18.1309571 57.2119639,14.268346 L57.2119639,8.63144378 C57.2119639,8.44654044 57.3410894,8.35422822 57.5262717,8.35422822 L59.1892861,8.35422822 C59.3744683,8.35422822 59.448095,8.44654044 59.448095,8.63144378 L59.448095,13.861726 C59.448095,16.5970682 59.8734006,16.7264727 61.6105994,16.7264727 L63.7360117,16.7264727 L63.7360117,8.63144378 C63.7360117,8.44654044 63.8283239,8.35422822 64.0132272,8.35422822 L65.7136128,8.35422822 Z" id="Fill-10" fill="#093D7C"></path>
                  <path d="M73.2809281,8.46508656 C73.4658314,8.52030656 73.5768292,8.594491 73.5768292,8.79752211 L73.5768292,9.92506989 C73.5768292,10.1283799 73.4658314,10.220971 73.2625214,10.2022854 L71.5805426,10.2022854 C70.4719592,10.2022854 70.1389659,10.3871888 70.1389659,11.2004288 L70.1389659,18.2973143 C70.1389659,18.4822177 70.0466537,18.5745299 69.8617503,18.5745299 L68.1800503,18.5745299 C67.995147,18.5745299 67.9028348,18.4822177 67.9028348,18.2973143 L67.9028348,10.6273121 C67.9028348,8.42799433 69.6587192,8.26177656 71.0261114,8.26177656 C71.5069159,8.26177656 72.7819959,8.31727544 73.2809281,8.46508656" id="Fill-12" fill="#093D7C"></path>
                  <path d="M76.7771071,12.7159668 L81.0095249,12.7159668 C80.9908393,10.719959 80.5842193,10.2023412 78.9396116,10.2023412 C77.2208193,10.2023412 76.7955138,10.719959 76.7771071,12.7159668 M83.2643416,13.270119 L83.2643416,13.824829 C83.2643416,14.1756712 83.1533438,14.3237612 82.6360049,14.3237612 L76.7771071,14.3237612 C76.832606,16.2826768 77.2760393,16.726389 79.069016,16.726389 L82.2293849,16.726389 C82.4140093,16.726389 82.5066004,16.8187012 82.5066004,17.0036046 L82.5066004,18.1492801 C82.5066004,18.352869 82.4140093,18.4267746 82.2477916,18.4635879 C81.2683338,18.6116779 80.1223793,18.6668979 78.9396116,18.6668979 C75.3355304,18.6668979 74.5222904,17.6319412 74.5222904,13.4737079 C74.5222904,9.296789 75.3355304,8.26155344 78.9396116,8.26155344 C82.4140093,8.26155344 83.2272493,9.25969678 83.2643416,13.270119" id="Fill-14" fill="#093D7C"></path>
                  <path d="M92.6353987,4.84251511 C92.7832098,4.86092178 92.8387087,4.93510622 92.8387087,5.04582511 L92.8387087,5.67416178 C92.8387087,5.82225178 92.7832098,5.89587844 92.6538053,5.89587844 L88.5694776,5.89587844 C86.7396876,5.89587844 85.8528209,6.45058844 85.8528209,7.76276067 L85.8528209,8.05838289 C85.8528209,9.07493289 86.0929442,9.629364 87.3680242,10.1469818 L91.5820353,11.9212729 C93.3008276,12.679014 93.5593576,13.898874 93.5593576,15.0445496 L93.5593576,15.5989807 C93.5593576,18.4450418 91.2680064,18.6667584 88.902192,18.6667584 C87.5900198,18.6667584 85.7970431,18.6115384 84.4112442,18.4637273 C84.2818398,18.4637273 84.2263409,18.3895429 84.2263409,18.2420107 L84.2263409,17.6318018 C84.2263409,17.5026762 84.318932,17.4287707 84.4483364,17.4287707 L89.3456253,17.4287707 C91.0276042,17.4287707 92.1548731,17.0592429 92.1548731,15.580574 L92.1548731,15.173954 C92.1548731,14.3423073 91.9699698,13.5106607 90.7132964,12.9746362 L86.1852564,11.1080329 C84.6697742,10.4796962 84.4296509,9.38924067 84.4296509,8.11388178 L84.4296509,7.70726178 C84.4296509,5.06451067 86.4811576,4.65789067 89.0315964,4.65789067 C90.325362,4.65789067 91.2493209,4.71311067 92.6353987,4.84251511" id="Fill-16" fill="#093D7C"></path>
                  <path d="M96.4899778,4.75020289 C96.6377889,4.75020289 96.7301011,4.86092178 96.7301011,5.00901178 L96.7301011,12.1800818 L100.03856,8.74221844 C100.130872,8.63149956 100.260277,8.57600067 100.408088,8.57600067 L101.572449,8.57600067 C101.849664,8.57600067 101.849664,8.72381178 101.701574,8.89030844 L98.1532711,12.4941107 L102.829122,18.1678262 C102.958527,18.3527296 102.977212,18.5744462 102.551907,18.5744462 L101.627948,18.5744462 C101.480137,18.5744462 101.350732,18.5008196 101.25842,18.3895429 L96.7301011,12.8084184 L96.7301011,18.3159162 C96.7301011,18.5008196 96.6377889,18.5744462 96.4899778,18.5744462 L95.6215178,18.5744462 C95.4734278,18.5744462 95.3808367,18.5008196 95.3808367,18.3159162 L95.3808367,5.00901178 C95.3808367,4.86092178 95.4734278,4.75020289 95.6215178,4.75020289 L96.4899778,4.75020289 Z" id="Fill-18" fill="#093D7C"></path>
                  <path d="M111.60556,8.575917 C111.753371,8.575917 111.845684,8.66822922 111.845684,8.81604033 L111.845684,17.7798081 C111.827277,21.3283903 111.106349,22.2894414 107.724264,22.2894414 C106.689307,22.2894414 106.023878,22.2523492 105.137011,22.160037 C104.988921,22.1416303 104.915295,22.0493181 104.915295,21.9012281 L104.915295,21.3283903 C104.915295,21.1803003 104.988921,21.1066737 105.137011,21.1066737 L107.724264,21.1066737 C109.997487,21.1066737 110.496419,20.533557 110.496419,18.6669537 L110.496419,18.5188637 C109.51724,18.6482681 108.777627,18.6669537 107.853668,18.6669537 C104.416084,18.6669537 103.787747,17.6504037 103.787747,13.5660759 L103.787747,8.81604033 C103.787747,8.66822922 103.861653,8.575917 104.009464,8.575917 L104.878203,8.575917 C105.044699,8.575917 105.118326,8.66822922 105.118326,8.81604033 L105.118326,13.5289837 C105.118326,16.6893526 105.414227,17.483907 107.853668,17.483907 C108.629816,17.483907 109.535368,17.4473726 110.496419,17.3179681 L110.496419,8.81604033 C110.496419,8.66822922 110.588731,8.575917 110.736821,8.575917 L111.60556,8.575917 Z" id="Fill-20" fill="#093D7C"></path>
              </g>
          </g>
      </g>
    </svg>
  );
}