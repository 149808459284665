import { Counter } from "./components/Counter";
import { FetchSentinel } from "./components/FetchSentinel";
//import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    path: '/sentinel-assessment',
    element: <FetchSentinel />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  // {
  //   path: '/sentinel-assessment',
  //   element: <FetchSentinel />
  // }
];

export default AppRoutes;
