import  { Component } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import './fetchSentinel.css';
import { ToastContainer, toast } from 'react-toastify';
import { UncontrolledTooltip } from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import "jspdf/dist/polyfills.es.js";
import { SurveySentinelSurvey } from './SurveySentinelController.js';
import ss_banner from './img/SS_logo_background-900w.png';

export class FetchSentinel extends Component {
  static displayName = FetchSentinel.name;

  constructor(props) {
    super(props);
    this.state = { survey: [], loading: true , showReport: -1, modal: false, contactLoaded: false };
  }

  componentDidMount() {    
    this.populateSurveyData();
  
  }
  static renderReturnButton() {
    return (<>    
      <button className="return_btn btn" type="submit"
          onClick={()=>{  
            window.location.href = 'https://www.securesky.com'
          }}>Cancel</button>
    </>);
  }

  static renderSurveyTable(survey, app) {
    return (<>    
            <MainBack>
              <LoginSurr key={"LOGIN-LOGIN-SURR"}>
                <div id="main-survey-surr">
                  {app.state.showReport === -1 && 
                  (<div>
                    <ToastContainer
                        position="top-center"
                        autoClose="3000"
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        hideProgressBar={true}
                        pauseOnHover={false}
                        draggable={false}
                        />
                    
                    
                      <b>SecureSky Microsoft Sentinel Assessment​</b><br />
                      <p>Take this quick 10-question assessment to see if SecureSky and Microsoft Sentinel are right for your organization.</p>
                      <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                          <tr>
                            <th>Does your organization …​</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {survey && survey.length && survey.map(svyItem =>
                            <tr key={svyItem.question} className={svyItem.idx % 2 ? "" : "survey_alt_row"}>
                              <td>
                                  <div>{(svyItem.idx + 1) + "." + parse('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + (svyItem.idx < 9? '&nbsp;&nbsp;': '')) 
                                    +  svyItem.question}</div>
                              </td>
                              <td>
                                <span className="yesno_text">Yes</span>
                                <input type="radio" value="1" name={svyItem.idx + "_yes_no"} onChange={()=> {
                                  let svy = app.state.survey;
                                  svy[svyItem.idx].answer = true;
                                  app.setState({ survey: svy });//, showReport: 0 
                                  }}
                                  className="yesno_radio"></input>
                              </td>
                              <td>
                                <span className="yesno_text">No</span>
                                <input type="radio" value="2" name={svyItem.idx + "_yes_no"} onChange={()=> {
                                  let svy = app.state.survey;
                                  svy[svyItem.idx].answer = false;
                                  app.setState({ survey: svy}, ()=> {});
                                  }}
                                  className="yesno_radio">
                                  </input>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="yesno_submit_wrap">
                        <div className="yesno_submit_wrap_inner">
                          <button className="yesno_submit btn" type="submit"
                            onClick={()=>{  
                              let svyUnanswered = app.state.survey.filter((s)=>{ return s.answer === null });
                              if (svyUnanswered.length > 0) {
                                toast.info("Please answer all questions to continue", {});
                              } else {
                                let totalFalse = app.state.survey.filter((a)=>{return a.answer === false}).length;
                                app.setState({ showReport: app.state.survey.length === totalFalse ? 1 : 0});
                              }
                            }}>Get Report</button>
                            {FetchSentinel.renderReturnButton()}
                        </div>
                      </div>
                      </div>)}
              {app.state.showReport > -1 && 
              (
                <div>
                  <table className='table table-striped table-answers' aria-labelledby="tabelLabel" id="report-table">
                    <thead>
                      <tr>
                        <th>Based On:</th> 
                        <th>Based on your answers, SecureSky's Managed Detection and Response (MDR) services, utilizing Microsoft Sentinel, can help your organization:</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {survey && survey.length && survey.map(svyItem =>
                        <tr key={svyItem.question} className={svyItem.idx % 2 ? "" : "survey_alt_row"}>
                          <td className={svyItem.idx % 2 ? "gray-row" : "gray-row-alt"}>{svyItem.answer === true ? "Yes" : "No"}<div className="arrow-surr">&#9654;</div></td>
                          <td><div className="diamond-symbol">&#9679;</div>{svyItem.answer === true ? svyItem.responseYes : svyItem.responseNo }</td>
                          <td>
                            <button className={svyItem.idx % 2 ? "how-button" : "how-button-alt"}
                              onClick={()=>{ return false }} id={ "how" + svyItem.idx }>How?</button>                        
                              <UncontrolledTooltip placement="left" target={"how" + svyItem.idx} trigger="hover">
                                <div className="tootltip-hover">
                                  {svyItem.answer === true ? svyItem.reflectHowYes : svyItem.reflectHowNo}
                                </div>
                              </UncontrolledTooltip>
                          </td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                    
                    {app.state.showReport > -1 && (
                        <DirectSurround>
                          {parse(app.state.survey[app.state.showReport].responseDirect)}
                        </DirectSurround>
                      )} 
                      <div className="report_wrap">
                        <div className="report_wrap_inner">
                          <button className="download_submit btn" type="submit" id="download-report"
                            onClick={()=>{  
                              app.generatePDF2();
                            }}>Download Report</button>
                            <button className="contact_submit btn" type="submit" id="contact-report"
                              onClick={()=>{  
                                  window.open("https://share.hsforms.com/1gQ4pgnDIT-GiTJTnbf0Lnw3d4wz", "Contact", 'width=500,height=360');
                                  app.setState({contactLoaded: true}) 
                              }}>Contact Us to Begin Your Trial</button>
                              {FetchSentinel.renderReturnButton()}
                        </div>
                      </div>
                </div>                
              )}              
              </div>

            </LoginSurr>
            <CopySurr key={"LOGIN-MAIN-COPY"}>&copy;{"2018-" + new Date().getFullYear() + " SecureSky, Inc."}</CopySurr>
      </MainBack>
      </>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchSentinel.renderSurveyTable(this.state.survey, this);

    return (
      <div>
        {contents}
      </div>
    );
  }

  async populateSurveyData() {
    let sd = await SurveySentinelSurvey();
    this.setState({ survey: sd, loading: false });
  }

  generatePDF2() {
    const pdf = new jsPDF('p', 'px');    
    this.generatePDFHeader(pdf);
    
    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont('Helvetica', 'bold');
    pdf.text("SecureSky Microsoft Sentinel Assessment Report", 125, 53);

    pdf.setFontSize(10.5);
    pdf.setFont('Helvetica', 'normal');
    pdf.text("Thank you for completing SecureSky's Microsoft Sentinel Assessment.", 30, 75);

    pdf.text("As a leading Microsoft partner and provider of Microsoft Sentinel solutions, our analysis indicates SecureSky's", 30, 93);
    pdf.text("Managed Detection and Response (MDR) services, utilizing Microsoft Sentinel, is a fit for your organization.", 30, 105);
    
    pdf.text("Below is a recap of the assessment questions (all beginning with, “Does your organization …”), your response,", 30, 124);
    pdf.text("and a short description of how a managed Microsoft Sentinel solution provides value.", 30, 136);

    let startHere = 155;
    this.generatePDFRows(pdf, startHere, 0, 5);
    this.generatePDFFooter(pdf, '1');

    pdf.addPage();
    this.generatePDFHeader(pdf);
    startHere = this.generatePDFRows(pdf, 55, 6, 9);
    
    pdf.setFontSize(10.5);
    pdf.setFont('Helvetica', 'normal');
    pdf.text("Try out Sentinel to see if it is right for your organization.&nbsp;&nbsp;Microsoft offers a 31 day free trial,", 30, startHere + 20);
    pdf.text("with up to 10 GB per day of ingestion.", 30,  startHere + 32);
    
    pdf.text("SecureSky, a Microsoft Gold Partner and leading Managed Sentinel and Cloud Security Posture Management", 30, startHere + 52);
    pdf.text("provider, offers no-cost workshops and affordable proof-of-concept services for you to get the most out", 30, startHere + 64);
    pdf.text("of your trial period.", 30, startHere + 76);
    
    pdf.text("We would be happy to review the results of your assessment with you or discuss next steps in your cybersecurity", 30, startHere + 96);
    pdf.text("journey.  For a no-cost, no-hassle discussion, please do not hesitate to visit us at www.securesky.com,", 30, startHere + 108);
    pdf.text("or reach out to info@securesky.com.", 30, startHere + 120);
    
    pdf.text("Thanks again, for completing SecureSky's Microsoft Sentinel Assessment.", 30, startHere + 140);

    this.generatePDFFooter(pdf, '2');

    pdf.save(`${new Date().toISOString()}.pdf`);
  }

  generatePDFHeader = (pdf) => {
    var img = new Image();
    img.src = ss_banner;
    pdf.addImage(img, 'png', 0, 0, 450, 38)
    pdf.setFillColor(0, 0, 0);
    pdf.rect(0, 38, 450, .5, 'F');
  }

  generatePDFRows = (pdf, startHere, fromIdx, endIdx) => {
    let survey = this.state.survey;
    survey && survey.length && survey.filter(i=>{return i.idx >= fromIdx && i.idx <= endIdx}).map(svyItem => {      
      let respTxt = (svyItem.answer === true ? svyItem.reflectHowYesRep : svyItem.reflectHowNoRep);
      let line2exists = respTxt.length > 1;
      let line3exists = respTxt.length > 2;
      let line4exists = respTxt.length > 3;

      let rectangleHeight = line4exists ? 75 : line3exists ? 60 : line2exists ? 45 : 20;
      let isAltRow = !(svyItem.idx % 2);
      if(isAltRow === true) {
        pdf.setFillColor(217,229,238); 
        pdf.rect(30, startHere, 390, rectangleHeight, 'F');
      } else {
        pdf.setFillColor(255,255,255)
      }

      pdf.setTextColor(0, 0, 0);
      pdf.setFont('Helvetica', 'bold');
      pdf.setFontSize(8.5);
      let txtQuestionLine2Exisits =  svyItem.questionRep.length > 1;
      let txtQuestion = ((svyItem.idx + 1) + "." + (svyItem.idx <= 8 ? "    " : "   ") + svyItem.questionRep[0] + (txtQuestionLine2Exisits === true ? "" :  this.generateYesNo(svyItem)));
      pdf.text(txtQuestion, 34, startHere + 10);
      if (txtQuestionLine2Exisits === true) {
        pdf.text(svyItem.questionRep[1] + this.generateYesNo(svyItem), 46, startHere + 20);
        startHere = startHere + 10;
      }
      pdf.setFont('Helvetica', 'normal');
      pdf.setFontSize(9);
      //Line 1
      pdf.text(respTxt[0], 46, startHere + 25);
      if (line2exists) { //Line 2
        pdf.text(respTxt[1], 46, startHere + 37);
      }
      if (line3exists) { //Line 3
        pdf.text(respTxt[2], 46, startHere + 49);
      }
      if (line4exists) { //Line 3
        pdf.text(respTxt[3], 46, startHere + 61);
      }

      startHere = startHere + rectangleHeight + 2;              
      return svyItem;
    });
    
    return startHere;
  }

  generateYesNo = (svyItem) => {
    return " (" + (svyItem.answer === true  ? "Yes" : "No") +")"
  }

  generatePDFFooter = (pdf, pageNum) => {
    pdf.text("Page " + pageNum + " of 2", 203, 600);
    
    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(5.5);
    pdf.text("©2022 SecureSky, Inc. This document contains confidential and proprietary information for use only by SecureSky and its intended recipients, and must not be disclosed to unauthorized individuals, without express written consent.", 13, 615);
  }

  toggle = () => {
    this.setState({modal:!this.state.modal});
  }
}

const DirectSurround = styled.div`
    background: #fff;
    padding: 10px;
    position: relative;
    margin-left: 115px;
    margin-bottom: 10px;
    color: #3e3f42;
`;

const MainBack = styled.div`
    height: inherit;
    width: 100% !important;
`;

const LoginSurr = styled.div`
    background-color: #fff;
    margin: auto;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgb(46, 163, 242);
`;

const CopySurr = styled.div`
    color: lightgray;
    margin: auto;
    margin-top: 30px;
    font-size: 10pt;
    text-align: center;
`;