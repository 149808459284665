const SurveyQuestions = 
[
    "Operate in a high-risk cybersecurity sector such as government, financial services, healthcare, energy, or retail?", 
    "Fall under any governmental, regulatory, or legal compliance mandates, or cyber insurance obligations?", 
    "Have rapid revenue or geographic growth, or make acquisitions?", 
    "Currently utilize an in-house SIEM or security monitoring platform?", 
    "Work with an MSSP or MDR provider that utilizes a proprietary SIEM or security stack?", 
    "Manage an internal SOC or SecOps group?", 
    "Perform threat hunting to manage exposure and identify security issues sooner?", 
    "Need to reduce costs while improving security?", 
    "Utilize elements of the Microsoft \"XDR\" suite, for example 365 Defender, Sentinel, or Defender for Cloud?", 
    "Use SaaS (for example, Office 365, Google Workspace, Okta, or Salesforce) or IaaS (for example, Azure, AWS, or GCP) cloud services?"
];

const SurveyQuestionsRep = 
[
    ["Operate in a high-risk cybersecurity sector such as government, financial services, healthcare, energy, or retail?"], 
    ["Fall under any governmental, regulatory, or legal compliance mandates, or cyber insurance obligations?"], 
    ["Have rapid revenue or geographic growth, or make acquisitions?"], 
    ["Currently utilize an in-house SIEM or security monitoring platform?"], 
    ["Work with an MSSP or MDR provider that utilizes a proprietary SIEM or security stack?"], 
    ["Manage an internal SOC or SecOps group?"], 
    ["Perform threat hunting to manage exposure and identify security issues sooner?"], 
    ["Need to reduce costs while improving security?"], 
    ["Utilize elements of the Microsoft \"XDR\" suite, for example 365 Defender, Sentinel, or Defender for Cloud?"], 
    [
        "Use SaaS (for example, Office 365, Google Workspace, Okta, or Salesforce) or IaaS (for example, Azure, AWS, or GCP)",
        "cloud services?"
    ]
];

const ResponseYes = 
[
    "Take advantage of advanced threat intelligence",
    "Meet compliance and cybersecurity insurance requirements",
    "Rapidly scale security coverage",
    "Move to a leading next-generation SIEM, or use Sentinel to augment your existing SIEM",
    "Take control of your security data, while giving you the freedom to choose a third-party provider based on your changing needs",
    "Ease the pressure on your internal security resources",
    "Expand your threat hunting capabilities with additional tools, techniques, and training",
    "Control security platform operating costs",
    "Optimize the effectiveness of the integrated Microsoft XDR suite",
    "Integrate SecureSky's leading CSPM/SSPM  platform into your security program"
];

const ReflectHowYes = 
[
    "Microsoft Sentinel allows you to utilize millions of threat intelligence signals collected daily, and SecureSky's expertise enables quick connection to additional open-source and proprietary threat intelligence sources, to secure your organization based on real-life and active attack vectors.", 
    "The powerful combination of SecureSky and Microsoft secures regulated data such as PII or PHI and meets compliance and insurance requirements for monitoring of security events, retention of logs, detection and response to threats, deployment of security measures, and audit reporting.", 
    "Microsoft Sentinel, with SecureSky know how, provides quick connectivity to security data sources and segmentation of processing, access, and storage of data based on business unit, regional geography, or user roles and responsibilities.", 
    "SecureSky specializes in Microsoft Sentinel deployment and enablement, offering our clients the advantages of a next generation cloud-native SIEM, for example accelerated connectivity to cloud and on-premise security and threat intelligence data sources, advanced analytics, and security orchestration, automation and response (SOAR) capabilities. Microsoft Sentinel is recognized as a Leader by Forrester and Visionary by Gartner.", 
    "Using a security platform that operates in your own environment allows you to control your data and your regulatory compliance, while determining if and when you need outside assistance, or a different managed service provider.", 
    "SecureSky's methodology lowers threat volumes by continuously hardening your attack surface, and, where suitable, takes advantage of Microsoft Sentinel's advanced automation and workflow capabilities. SecureSky also focuses on knowledge transfer - training your hard to source and retain security resources to maximize their engagement.", 
    "SecureSky will help develop your threat hunters, working with them to develop new use cases and related queries and leading trigger, scenario-based, and free form threat hunting exercises to train them in the latest threat hunting techniques.", 
    "Microsoft Sentinel's consumption-based pricing and numerous data retention options permits you to create a variable security monitoring cost structure, while SecureSky's approach actively manages costs, continuously focusing on capturing the right data to detect and respond to threats and eliminating data with limited security value.", 
    "SecureSky, a Microsoft Gold Partner, are experts in the integrated Microsoft's XDR suite. When properly deployed and enabled, the Microsoft XDR suite prevents and detects attacks across your identities, endpoints, apps, email, data, and cloud apps, as well as provides a best-in-class platform (Microsoft Sentinel) to investigate and respond to attacks.", 
    "SecureSky's patented Active Protection Platform, proactively manages multi-cloud security posture, offering continuous risk and threat visibility, normalized remediation prioritization, and control and policy enforcement."
];    


const ReflectHowYesRep = 
[
    [
        "Microsoft Sentinel allows you to utilize millions of threat intelligence signals collected daily, and SecureSky's expertise",
        "enables quick connection to additional open-source and proprietary threat intelligence sources, to secure your organization", 
        "based on real-life and active attack vectors."
    ],
    [
        "The powerful combination of SecureSky and Microsoft secures regulated data such as PII or PHI and meets compliance and",
        "insurance requirements for monitoring of security events, retention of logs, detection and response to threats, deployment",
        "of security measures, and audit reporting."
    ], 
    [
        "Microsoft Sentinel, with SecureSky know how, provides quick connectivity to security data sources and segmentation of",
        "processing, access, and storage of data based on business unit, regional geography, or user roles and responsibilities."
    ], 
    [
        "SecureSky specializes in Microsoft Sentinel deployment and enablement, offering our clients the advantages of a next",
        "generation cloud-native SIEM, for example accelerated connectivity to cloud and on-premise security and threat",
        "intelligence data sources, advanced analytics, and security orchestration, automation and response (SOAR) capabilities.",
        "Microsoft Sentinel is recognized as a Leader by Forrester and Visionary by Gartner."
    ], 
    [
        "Using a security platform that operates in your own environment allows you to control your data and your regulatory",
        "compliance, while determining if and when you need outside assistance, or a different managed service provider.", 
    ],
    [
        "SecureSky's methodology lowers threat volumes by continuously hardening your attack surface, and, where suitable, takes",
        "advantage of Microsoft Sentinel's advanced automation and workflow capabilities. SecureSky also focuses on knowledge",
        "transfer - training your hard to source and retain security resources to maximize their engagement."
    ],
    [
        "SecureSky will help develop your threat hunters, working with them to develop new use cases and related queries and",
        "leading trigger, scenario-based, and free form threat hunting exercises to train them in the latest threat hunting techniques."
    ], 
    [
        "Microsoft Sentinel's consumption-based pricing and numerous data retention options permits you to create a variable",
        "security monitoring cost structure, while SecureSky's approach actively manages costs, continuously focusing on capturing",
        "the right data to detect and respond to threats and eliminating data with limited security value."
    ], 
    [   
        "SecureSky, a Microsoft Gold Partner, are experts in the integrated Microsoft's XDR suite. When properly deployed and",
        "enabled, the Microsoft XDR suite prevents and detects attacks across your identities, endpoints, apps, email, data, and",
        "cloud apps, as well as provides a best-in-class platform (Microsoft Sentinel) to investigate and respond to attacks."
    ], 
    [
        "SecureSky's patented Active Protection Platform, proactively manages multi-cloud security posture, offering continuous risk",
        "and threat visibility, normalized remediation prioritization, and control and policy enforcement."
    ]
];    

const ResponseNo = 
[
    "Further mitigate risk by focusing on enabling protective controls and detection policies",
    "Prepare to meet rapidly evolving security, privacy, and insurance requirements",
    "Enable flexible, low-cost security monitoring, able to rapidly scale when opportunities arise",
    "Implement an advanced SIEM without the obstacles associated with legacy SIEMS and MSSP/MDR providers",
    "Utilize a next-generation SIEM, while controlling how you resource your threat response program",
    "Face external sourcing and cost pressures by working with a leader focused on risk prevention and automation",
    "Proactively search for cyber threats to manage risk",
    "Continuously manage security platform operating costs",
    "Correlate disparate security data sources while searching for redundancy to lower costs",
    "Prepare for upcoming SaaS or cloud migrations or discover \"shadow IT\" deployments" 
];

const ReflectHowNo = 
[
    "SecureSky's methodology, together with Microsoft Sentinel's advanced analytics, lowers risk by continuously hardening your environment, enabling security controls to reduce your attack surface and improving detection policies to capture possible compromises earlier in the cyber kill chain.", 
    "The powerful combination of SecureSky and Microsoft secures regulated data such as PII or PHI, and meets compliance and insurance requirements for monitoring of security events, retention of logs, detection and response to threats, deployment of security measures, and audit reporting.", 
    "Microsoft Sentinel's consumption-based pricing and data retention options allow you to create a low-cost security monitoring solution. When rapid scalability is required, Microsoft Sentinel, with SecureSky know-how, provides pre-built connectivity, visualization, and alerting algorithms for quick security value.", 
    "Microsoft Sentinel addresses many of the issues that plague traditional SIEMs and third-party SOC providers by eliminating the cost and time associated with deploying hardware, providing rapid connectivity, and allowing visibility into risk and security threats across multi-cloud and hybrid environments. Well beyond legacy providers, Sentinel also provides intelligent security analytics and an advanced SOAR engine.", 
    "In todays tight labor markets, running an internal threat response group is a challenge. Changing SIEMs and re-training, or turning over your organization, is equally challenging. If you do not work with a third-party now, you need the flexibility of a SIEM like Microsoft Sentinel that can be managed internally or externally without large changeover costs.", 
    "Third-party MSSP and MDR providers are under the same staffing pressures as you are. With scarcity rapidly escalating salaries, if your third-party provider has not yet raised your price, they will at next contract renewal. SecureSky and Sentinel allow you to take control of your costs by lowering threat volumes and automating response elements.", 
    "SecureSky will develop threat hunting queries and perform trigger, scenario-based, and free form threat hunting exercises to proactively search for cyber threats and indicators of compromise, to manage address threats and risk in your environment before an incident progresses.", 
    "The one guarantee in cybersecurity is that If security visibility and budget are under control today, they won't be tomorrow. Microsoft Sentinel's consumption-based pricing and numerous data retention options permits you to create a variable security monitoring cost structure, while SecureSky's approach actively manages costs, continuously focusing on capturing the right data to detect and respond to threats and eliminating data with limited security value.", 
    "Microsoft Sentinel connectors, analytics, and SOAR capabilities extend to a majority of today's security technologies, allowing organizations to take advantage of Sentinel's advanced capabilities. SecureSky are experts at integrating wide ranging security stacks and searching for overlap or licensing opportunities to reduce vendors and costs.",         
    "SecureSky's patented Active Protection Platform, proactively manages multi-cloud security posture, offering continuous risk and threat visibility, normalized remediation prioritization, and control and policy enforcement. SecureSky's understanding of identity and access management, together Microsoft Sentinel's discovery functionality, help identify unsanctioned usage of cloud services."
];

const ReflectHowNoRep = 
[
    [
        "SecureSky's methodology, together with Microsoft Sentinel's advanced analytics, lowers risk by continuously hardening your",
        "environment, enabling security controls to reduce your attack surface and improving detection policies to capture possible",
        "compromises earlier in the cyber kill chain."
    ], 
    [
        "The powerful combination of SecureSky and Microsoft secures regulated data such as PII or PHI, and meets compliance",
        "and insurance requirements for monitoring of security events, retention of logs, detection and response to threats,",
        "deployment of security measures, and audit reporting."
    ], 
    [
        "Microsoft Sentinel's consumption-based pricing and data retention options allow you to create a low-cost security monitoring",
        "solution. When rapid scalability is required, Microsoft Sentinel, with SecureSky know-how, provides pre-built connectivity,",
        "visualization, and alerting algorithms for quick security value."
    ], 
    [
        "Microsoft Sentinel addresses many of the issues that plague traditional SIEMs and third-party SOC providers by eliminating",
        "the cost and time associated with deploying hardware, providing rapid connectivity, and allowing visibility into risk and",
        "security threats across multi-cloud and hybrid environments. Well beyond legacy providers, Sentinel also provides intelligent",
        "security analytics and an advanced SOAR engine."
    ], 
    [
        "In todays tight labor markets, running an internal threat response group is a challenge. Changing SIEMs and re-training, or",
        " turning over your organization, is equally challenging. If you do not work with a third-party now, you need the flexibility",
        "of a SIEM like Microsoft Sentinel that can be managed internally or externally without large changeover costs."
    ], 
    [
        "Third-party MSSP and MDR providers are under the same staffing pressures as you are. With scarcity rapidly escalating",
        "salaries, if your third-party provider has not yet raised your price, they will at next contract renewal. SecureSky and",
        "Sentinel allow you to take control of your costs by lowering threat volumes and automating response elements."
    ], 
    [
        "SecureSky will develop threat hunting queries and perform trigger, scenario-based, and free form threat hunting exercises to",
        "proactively search for cyber threats and indicators of compromise, to manage address threats and risk in your environment",
        "before an incident progresses."
    ], 
    [
        "The one guarantee in cybersecurity is that If security visibility and budget are under control today, they won't be tomorrow.",
        "Microsoft Sentinel's consumption-based pricing and numerous data retention options permits you to create a variable security",
        "monitoring cost structure, while SecureSky's approach actively manages costs, continuously focusing on capturing the right data",
        "to detect and respond to threats and eliminating data with limited security value."
    ], 
    [
        "Microsoft Sentinel connectors, analytics, and SOAR capabilities extend to a majority of today's security technologies,",
        "allowing organizations to take advantage of Sentinel's advanced capabilities. SecureSky are experts at integrating wide",
        "ranging security stacks and searching for overlap or licensing opportunities to reduce vendors and costs."
    ],
    [         
        "SecureSky's patented Active Protection Platform, proactively manages multi-cloud security posture, offering continuous risk",
        "and threat visibility, normalized remediation prioritization, and control and policy enforcement. SecureSky's understanding",
        "of identity and access management, together Microsoft Sentinel's discovery functionality, help identify unsanctioned usage",
        "of cloud services."
    ]
];


const ResponseDirect = 
[
    "Try out Sentinel to see if it is right for your organization. Microsoft offers a 31-day free trial, with up to 10GB per day of ingestion.<br>SecureSky, a Microsoft Gold Partner and leading Managed Sentinel provider, offers no-cost workshops and affordable proof-of-concept services for you to get the most out of your trial period.",
    "SecureSky offers a full portfolio of consulting and managed security services security to protect and monitor your environment.<br>Contact us for a free consultation to see how we can help your organization defend against cyber threats."
];

export let SurveySentinelSurvey = async() => {
    let clr = new Promise(async (resolve, reject) => {
        let svyArr = []; 
        SurveyQuestions.forEach((s,i)=>{
            svyArr.push({
                question:  SurveyQuestions[i],
                questionRep: SurveyQuestionsRep[i],
                idx: i,
                responseYes: ResponseYes[i],
                reflectHowYes: ReflectHowYes[i],
                reflectHowYesRep: ReflectHowYesRep[i],
                responseNo: ResponseNo[i],
                reflectHowNo: ReflectHowNo[i],
                reflectHowNoRep: ReflectHowNoRep[i],
                answer: null,
                responseDirect: (i <= 1 ? ResponseDirect[i] : null),
                });
        });
        resolve(svyArr);
    });
    return await clr;
}


